.sidebar {
  width: 60px;
  height: 100vh;
  background-color: #f8f1f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  position: fixed;
  left: 0;
  top: 0;
}

.menu-button {
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 10px;
}

.add-button {
  background-color: #f8b6c2;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 10px;
}

.labels {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.label-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.label-text {
  margin-top: 5px;
  font-size: 12px;
}

.content {
  margin-left: 40px; /* Make sure the content is not hidden behind the sidebar */
  padding: 20px;
}

.divider {
  width: 80%;  /* Adjust the width as needed */
  border: none;
  border-top: 1.5px solid #ccc;
  margin: 15px 0;
}

.content {
  margin-left: 70px;
  padding: 20px;
}
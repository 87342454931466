.App {
  font-family: 'Roboto', sans-serif;
  background-color: white;
}

.main-content {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  background-color: white;
}

.first-row, .second-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  background-color: white;
}

.left-column {
  flex: 1.4;
  display: flex;
  flex-direction: column;
  margin-right: 150px;
}

.right-column {
  flex: 1;
  margin-right: 0px;
}

.nl-query {
  margin-bottom: 20px;
}

.data-table-container {
  flex: 1;
}

.visualization {
  flex: 1;
}

.step-by-step-explanation {
  margin-top: 20px;
}

.step {
  padding: 20px;
  margin-top: 10px;
  background-color: #f3e5f5;
  border-radius: 4px;
}

.highlight {
  background-color: yellow;
}

.viz-step {
  margin-top: 20px;
}

/* .pagination {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  
} */

.pagination span {
  margin: 0 5px;
  cursor: pointer;
}

.pagination span:hover {
  text-decoration: underline;
}

.sidebar {
  width: 60px;
  height: 100vh;
  background-color: #f8f1f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  position: fixed;
  left: 0;
  top: 0;
}

.menu-button {
  margin-bottom: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 10px;
}

.add-button {
  background-color: #f8b6c2;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 10px;
}

.labels {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.label-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.label-text {
  margin-top: 5px;
  font-size: 12px;
}

.main-content {
  margin-left: 60px; /* Ensure content is not hidden behind the sidebar */
  margin-right: 100px;

  /* padding: 20px; */
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
  margin-left: 60px;
  /* margin-right: 110px; */
  /* padding: 15px; */
  background-color: white;
  border-bottom: 1.5px solid #ddd;
  padding-top: 20px;
  padding-bottom: 20px;
  /* position: fixed; */
  /* left: 0; */
  /* top: 0; */
}
.header .title {
  font-size: 24px; /* Increase font size of the title */
  color: #714288;
  font-weight: bolder;
  margin-left: 0px;
}

.header .vql-switch {
  display: flex;
  font-size: 24px;
  align-items: center;
}

.header .vql-switch .MuiTypography-body1 {
  font-size: 20px; /* Increase font size of the VQL switch label */
  margin-right: 10px;
  font-weight: bolder;
  color: #a78cc8;
}

.content {
  margin-top: 10px;
  margin-left: 40px;
}

.label-button.selected {
  background-color: #714288; /* Darker background for selected label */
  color: white; /* Change text/icon color for selected label */
}


.header .timer {
  display: flex;
  font-size: 24px;
  gap: 8px;
  align-items: center;
}